import React, { useState } from "react";
import {
  Button,
  Typography,
  Box,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";
import styled from "styled-components";
import { alpha, styled as muiStyled } from "@mui/material/styles";
import {
  DesktopDatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import validator from "validator";
import emailjs from "@emailjs/browser";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const PinkBorderTextField = styled(TextField)`
  && {
    & label.Mui-focused {
      color: #f8bbd0;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #f8bbd0;
      }
    }
    & .MuiFormHelperText-root {
      color: red;
    }
  }
`;

const PinkSwitch = muiStyled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#e9b6ce",
    "&:hover": {
      backgroundColor: alpha("#e9b6ce", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#e9b6ce",
  },
}));

const Contact = () => {
  const { isDevice } = useScreenSizes();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [numberOfGuests, setNumberOfGuests] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [privateEvent, setPrivateEvent] = useState(false);
  const [templateId, setTemplateId] = useState("template_odum9mi");

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const serviceId = "service_bzd1lj6";
    const publicKey = "53U0rQHrSrDIEmca-";

    const templateParams = {
      from_name: name,
      from_email: email,
      phone_number: phoneNumber,
      guest_number: numberOfGuests,
      event_date: eventDate,
      time: eventTime,
      message: message,
      to_name: "Coco and Marlo",
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        // Clear the form fields after submission
        setName("");
        setEmail("");
        setMessage("");
        setPhoneNumber("");
        setNumberOfGuests("");
        setEventDate("");
        setEventTime("");
        setPrivateEvent(false);
      })
      .catch((err) => {
        throw new Error("Failed", err);
      });
  };

  const validateEmail = () => {
    if (validator.isEmail(email)) {
      return true;
    }
    return false;
  };

  const handleEventTimeChange = (event: any) => {
    setEventTime(event.target.value);
  };

  const timeOptions = [
    "11:00AM",
    "11:15AM",
    "11:30AM",
    "11:45AM",
    "12:00PM",
    "12:15PM",
    "12:30PM",
    "12:45PM",
    "1:00PM",
    "1:15PM",
    "1:30PM",
    "1:45PM",
    "2:00PM",
    "2:15PM",
    "2:30PM",
    "2:45PM",
    "3:00PM",
    "3:15PM",
    "3:30PM",
    "3:45PM",
    "4:00PM",
    "4:15PM",
    "4:30PM",
    "4:45PM",
    "5:00PM",
    "5:15PM",
    "5:30PM",
    "5:45PM",
    "6:00PM",
  ];

  const handleDisableSubmit = () => {
    if (privateEvent) {
      if (
        !name ||
        !email ||
        !message ||
        !numberOfGuests ||
        eventDate.length === 0 ||
        !eventTime
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (!name || !email || !message) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <Box
      sx={{
        padding: "2rem",
      }}
    >
      <Typography
        variant={isDevice ? "h3" : "h1"}
        textAlign={"center"}
        mb={isDevice ? "1rem" : "0"}
      >
        Contact Us
      </Typography>
      <Typography textAlign={"center"} variant="body1">
        Here are few ways to get in touch with us.
      </Typography>
      <Box margin="2rem auto" textAlign={isDevice ? "left" : "center"}>
        <Typography variant="subtitle1" mb="0.5rem" fontWeight="400">
          Call Us
        </Typography>
        <Typography>
          <a
            href="tel:6134224200"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            (613)-422-4200
          </a>
        </Typography>
      </Box>
      <Box textAlign={isDevice ? "left" : "center"}>
        <Typography variant="subtitle1" mb="0.5rem" fontWeight="400">
          Email
        </Typography>
        <Typography>
          <a
            href="mailto:cocoandmarlo@dreamlandcafe.ca"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            cocoandmarlo@dreamlandcafe.ca
          </a>
        </Typography>
      </Box>
      <Box
        margin="2rem auto"
        display="flex"
        flexDirection="column"
        justifyContent={"center"}
        width={isDevice ? "100%" : "39.125rem"}
        gap="2rem"
      >
        <Box display="flex" flexDirection="column" gap="1rem">
          <Typography variant="subtitle1" mb="0.5rem" fontWeight="400">
            Message Us
          </Typography>
          <PinkBorderTextField
            variant="outlined"
            label="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required
          />
          <PinkBorderTextField
            variant="outlined"
            label="Email"
            fullWidth
            value={email}
            helperText={
              validateEmail() || email?.length === 0 ? "" : "Invalid Email"
            }
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <PinkBorderTextField
            variant="outlined"
            label="Phone Number"
            fullWidth
            multiline
            // rows={10}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <FormGroup>
            <FormControlLabel
              control={<PinkSwitch />}
              label="Private Event"
              onChange={() => {
                setPrivateEvent(!privateEvent);
                setTemplateId("template_h3uyh2e");
              }}
            />
          </FormGroup>
          {privateEvent && (
            <>
              <Box display="flex" justifyContent={"space-between"}>
                <PinkBorderTextField
                  id="standard-required"
                  required
                  variant="outlined"
                  label="NUMBER OF GUESTS"
                  value={numberOfGuests}
                  onChange={(e) => setNumberOfGuests(e.target.value)}
                />
              </Box>

              <Box display="flex" gap="1rem">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {isDevice && (
                    <MobileDatePicker
                      label="Event Date"
                      defaultValue={dayjs("2024-01-01")}
                      onChange={(selectedDate) => {
                        setEventDate(
                          selectedDate?.format("DD-MM-YYYY") ||
                            new Date().getDate().toString()
                        );
                      }}
                    />
                  )}
                  {!isDevice && (
                    <DesktopDatePicker
                      label="Event Date"
                      defaultValue={dayjs("2024-01-01")}
                      onChange={(selectedDate) => {
                        setEventDate(
                          selectedDate?.format("DD-MM-YYYY") ||
                            new Date().getDate().toString()
                        );
                      }}
                    />
                  )}
                </LocalizationProvider>
                <Box width="40%">
                  <FormControl fullWidth>
                    <InputLabel
                      id="event-time-label"
                      sx={{
                        "&.Mui-focused": {
                          color: "#F8BBD0",
                        },
                      }}
                      required
                    >
                      Time
                    </InputLabel>
                    <Select
                      labelId="event-time-label"
                      id="event-time-select"
                      value={eventTime}
                      label="Event Time"
                      onChange={handleEventTimeChange}
                      required
                      sx={{
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#F8BBD0",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#F8BBD0",
                        },
                      }}
                    >
                      {timeOptions.map((time) => (
                        <MenuItem key={time} value={time}>
                          {time}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </>
          )}
          {privateEvent && (
            <Typography
              variant="subtitle2"
              mb="-0.5rem"
              mt="0.5rem"
              fontWeight="400"
            >
              Tell us a little bit about your event!
            </Typography>
          )}
          <PinkBorderTextField
            variant="outlined"
            label="Message"
            fullWidth
            multiline
            rows={10}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />

          <Typography fontSize="14px">
            For privacy and security purposes, please do not share your credit
            card or payment information in this field. {isDevice && <br />}
            Thank you.
          </Typography>
        </Box>

        <Box width="30%" margin="0 auto 2rem auto">
          <Button
            variant="contained"
            sx={{
              width: "100%",
              height: "3rem",
              borderRadius: "30px",
              backgroundColor: "#e9b6ce",
              padding: "1rem",
              "&:hover": {
                backgroundColor: "#e9b6ce",
              },
            }}
            type="submit"
            onClick={handleSubmit}
            disabled={handleDisableSubmit()}
          >
            <Typography variant="h6">SEND</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
