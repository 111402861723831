import React from 'react'
import { Container, Box, Typography } from '@mui/material'
// @ts-ignore
import angelPicture from '../images/angel-picture.jpeg'
import { useScreenSizes } from '../hooks/use-screens/useScreenSizes';

const About = () => {
    const { isDevice } = useScreenSizes()

  return (
    <Container
        sx={{
            margin: 'auto',
            backgroundImage: `url(${angelPicture})`,
            backgroundSize: 'cover',
            backgroundPosition: 'bottom',
            alignItems: 'center',
            paddingTop: '3rem',
        }}
        maxWidth={false}
    >
        <Typography
            variant={ isDevice ? 'h5' : 'h4'}
            color="#E9E9D1"
            textAlign="center"
        >
            Located in the heart of Ottawa’s Little Italy, <br />DreamLand Cafe offers incredible fresh pasta dishes, made with love and finesse.
        </Typography>
        { !isDevice && (
            <Box
                color="#E9E9D1"
                display="flex"
                gap="1rem"
                padding="2rem"
                borderRadius="25px"
                height="60%"
                justifyContent="space-between"
            >
                <Box
                    textAlign={"left"}
                    width="30%"
                    mt="10rem"
                >
                    <Typography variant='subtitle1' mb="1rem">
                        Since 2017, sisters, Coco and Marlo,<br /> have owned and operated their fresh pasta restaurant.
                    </Typography>
                    <Typography variant='subtitle1' mb="1rem">
                        The welcoming atmosphere and intoxicating smells that move through the space, sure makes for a cozy spot. <br />Guests find themselves coming back time and time again whether it’s to celebrate, catch up with a friend, or just eat a dang good meal.
                    </Typography>
                    <Typography variant='subtitle1'>
                        Co-owner and head chef, Coco De Leo, puts her own spin on classic Italian family recipes. <br />She puts a successful effort into providing vegan and gluten free options all across the board in addition to the standard options. <br /> <br />The menu evolves every year, improving and maturing as she hones her craft and builds her kitchen team.
                    </Typography>
                </Box>
                
                <Box
                    textAlign={"right"}
                    margin={"auto 0 0 0"}
                    width="25%"
                >
                    <Typography variant='subtitle1'>
                        Co-owner and general manager, Marlo De Leo, <br /> has built an ever changing bar menu with exciting, yet approachable bevys. Everything is curated and handpicked from the natural wines, to the Ontario craft beers. The cocktails are constantly evolving with the seasons, and the non alcoholic offerings are enjoyed by the many.
                    </Typography>
                    <Typography variant='subtitle1' textAlign={"center"} mt="1rem">
                        As chef Coco would say, <br /> “I cook. You eat.” <br />
                        We can’t wait to serve you up some hot noods!!! <br />Come by soon, yeah?
                    </Typography>
                </Box>
            </Box>
        )}
        { isDevice && (
            <Box
                color="#E9E9D1"
                display="flex"
                flexDirection="column"
                gap="1rem"
                padding="0.5rem"
                height="60%"
                justifySelf={"center"}
                margin={"0 auto"}
            >
                <Box
                    textAlign="center"
                    width="100%"
                >
                    <p style={{ textShadow: '0px 0px 12px #000000', fontFamily: 'Helvetica', fontWeight: 700, lineHeight: '1.4rem', fontSize: '1.25rem' }}>
                        Since 2017, sisters, Coco and Marlo, have owned and operated their fresh pasta restaurant.
                    </p>
                    <p style={{ textShadow: '0px 0px 12px #000000', fontFamily: 'Helvetica', fontWeight: 700, lineHeight: '1.4rem', fontSize: '1.25rem' }}>
                        The welcoming atmosphere and intoxicating smells that move through the space, sure makes for a cozy spot. Guests find themselves coming back time and time again whether it’s to celebrate, catch up with a friend, or just eat a dang good meal.
                    </p>
                    <p style={{ textShadow: '0px 0px 12px #000000', fontFamily: 'Helvetica', fontWeight: 700, lineHeight: '1.4rem', fontSize: '1.25rem' }}>
                        Co-owner and head chef, Coco De Leo, puts her own spin on classic Italian family recipes. She puts a successful effort into providing vegan and gluten free options all across the board in addition to the standard options. The menu evolves every year, improving and maturing as she hones her craft and builds her kitchen team.
                    </p>
                </Box>
                
                <Box
                    textAlign="center"
                    margin={"auto 0 0 0"}
                    width="100%"
                >
                    <p style={{ textShadow: '0px 0px 12px #000000', fontFamily: 'Helvetica', fontWeight: 700, lineHeight: '1.4rem', fontSize: '1.25rem' }}>
                        Co-owner and general manager, Marlo De Leo, has built an ever changing bar menu with exciting, yet approachable bevys. Everything is curated and handpicked from the natural wines, to the Ontario craft beers. The cocktails are constantly evolving with the seasons, and the non alcoholic offerings are enjoyed by the many.
                    </p>
                    <p style={{ textShadow: '0px 0px 12px #000000', fontFamily: 'Helvetica', fontWeight: 700, lineHeight: '1.4rem', fontSize: '1.25rem' }}>
                        As chef Coco would say, <br /> “I cook. You eat.” <br />
                        We can’t wait to serve you up some hot noods!!! <br />Come by soon, yeah?
                    </p>
                </Box>
            </Box>
        )}
    </Container>
  )
}

export default About