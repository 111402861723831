import React, { useState } from "react";
import {
  Button,
  Typography,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";
import styled from "styled-components";
import validator from "validator";
import emailjs from "@emailjs/browser";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  LocalizationProvider,
  MobileDatePicker,
  DesktopDatePicker,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";

const PinkBorderTextField = styled(TextField)`
  && {
    & label.Mui-focused {
      color: #f8bbd0;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #f8bbd0;
      }
    }
    & .MuiFormHelperText-root {
      color: red;
    }
  }
`;

const Catering = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [numberOfGuests, setNumberOfGuests] = useState("");
  const [deliveryType, setDeliveryType] = useState("Pickup");
  const [eventDate, setEventDate] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [eventAddress, setEventAddress] = useState("");
  const [message, setMessage] = useState("");

  const { isDevice } = useScreenSizes();

  const handleDeliveryTypeChange = (event: any) => {
    setDeliveryType(event.target.value);
  };

  const handleEventTimeChange = (event: any) => {
    setEventTime(event.target.value);
  };

  const timeOptions = [
    "11:00AM",
    "11:15AM",
    "11:30AM",
    "11:45AM",
    "12:00PM",
    "12:15PM",
    "12:30PM",
    "12:45PM",
    "1:00PM",
    "1:15PM",
    "1:30PM",
    "1:45PM",
    "2:00PM",
    "2:15PM",
    "2:30PM",
    "2:45PM",
    "3:00PM",
    "3:15PM",
    "3:30PM",
    "3:45PM",
    "4:00PM",
    "4:15PM",
    "4:30PM",
    "4:45PM",
    "5:00PM",
    "5:15PM",
    "5:30PM",
    "5:45PM",
    "6:00PM",
  ];

  const validateEmail = () => {
    return validator.isEmail(email);
  };

  const handleSubmit = () => {
    // Construct the data object to be submitted
    const templateParams = {
      firstName,
      lastName,
      email,
      phoneNumber,
      businessName,
      numberOfGuests,
      deliveryType,
      eventDate,
      eventTime,
      eventAddress,
      message,
    };

    const serviceId = "service_bzd1lj6";
    const templateId = "template_ljpo8ig";
    const publicKey = "53U0rQHrSrDIEmca-";

    // Perform form submission logic here
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        // Clear the form fields after submission
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        setBusinessName("");
        setNumberOfGuests("");
        setEventDate("");
        setEventTime("");
        setMessage("");
        setEventAddress("");
      })
      .catch((err) => {
        throw new Error("Failed", err);
      });
  };

  const handleDisableSubmit = () => {
    switch (deliveryType) {
      case "Pickup":
        if (
          !firstName ||
          !lastName ||
          !email ||
          !phoneNumber ||
          !numberOfGuests ||
          eventDate.length === 0 ||
          !eventTime ||
          !message
        ) {
          return true;
        } else {
          return false;
        }

      case "Delivery":
        if (
          !firstName ||
          !lastName ||
          !email ||
          !phoneNumber ||
          !numberOfGuests ||
          eventDate.length === 0 ||
          !eventTime ||
          !eventAddress ||
          !message
        ) {
          return true;
        } else {
          return false;
        }

      default:
        return true;
    }
  };

  return (
    <Box
      sx={{
        padding: "2rem",
      }}
    >
      <Typography
        variant={isDevice ? "h3" : "h1"}
        textAlign={"center"}
        mb={isDevice ? "1rem" : "0"}
      >
        GET A {isDevice ? <br /> : ""}CUSTOM CATERING QUOTE
      </Typography>
      <Typography textAlign={"center"} variant="body1">
        Want to Know more about Dreamland Catering or need help with an order
        you've already placed?
      </Typography>
      <Typography textAlign={"center"} variant="body1">
        Contact support for direct assistance.
      </Typography>

      <Box
        margin="3rem auto"
        display="flex"
        flexDirection="column"
        justifyContent={"center"}
        width={isDevice ? "100%" : "39.125rem"}
        gap="2rem"
      >
        <PinkBorderTextField
          id="standard-required"
          required
          variant="outlined"
          label="FIRST NAME"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <PinkBorderTextField
          id="standard-required"
          required
          variant="outlined"
          label="LAST NAME"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <PinkBorderTextField
          id="standard-required"
          required
          variant="outlined"
          label="EMAIL ADDRESS"
          value={email}
          helperText={
            validateEmail() || email?.length === 0 ? "" : "Invalid Email"
          }
          onChange={(e) => setEmail(e.target.value)}
        />
        <PinkBorderTextField
          id="standard-required"
          required
          variant="outlined"
          label="PHONE NUMBER"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <PinkBorderTextField
          id="standard-basic"
          variant="outlined"
          label="BUSINESS NAME"
          value={businessName}
          onChange={(e) => setBusinessName(e.target.value)}
        />

        <Box display="flex" gap="1rem">
          <PinkBorderTextField
            id="standard-required"
            required
            variant="outlined"
            label="NUMBER OF GUESTS"
            value={numberOfGuests}
            onChange={(e) => setNumberOfGuests(e.target.value)}
          />
          <Box width="40%">
            <FormControl fullWidth>
              <InputLabel
                id="delivery-type-label"
                sx={{
                  "&.Mui-focused": {
                    color: "#F8BBD0",
                  },
                }}
              >
                DELIVERY TYPE
              </InputLabel>
              <Select
                labelId="delivery-type-label"
                id="delivery-type-select"
                value={deliveryType}
                label="Delivery Type"
                onChange={handleDeliveryTypeChange}
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#F8BBD0",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#F8BBD0",
                  },
                }}
              >
                <MenuItem value="Pickup">Pickup</MenuItem>
                <MenuItem value="Delivery">Delivery</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box display="flex" gap="1rem">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {isDevice && (
              <MobileDatePicker
                label="Event Date"
                defaultValue={dayjs("2024-01-01")}
                onChange={(selectedDate) => {
                  setEventDate(
                    selectedDate?.format("DD-MM-YYYY") ||
                      new Date().getDate().toString()
                  );
                }}
              />
            )}
            {!isDevice && (
              <DesktopDatePicker
                label="Event Date"
                defaultValue={dayjs("2024-01-01")}
                onChange={(selectedDate) => {
                  setEventDate(
                    selectedDate?.format("DD-MM-YYYY") ||
                      new Date().getDate().toString()
                  );
                }}
              />
            )}
          </LocalizationProvider>
          <Box width="40%">
            <FormControl fullWidth>
              <InputLabel
                id="event-time-label"
                sx={{
                  "&.Mui-focused": {
                    color: "#F8BBD0",
                  },
                }}
              >
                Event Time
              </InputLabel>
              <Select
                labelId="event-time-label"
                id="event-time-select"
                value={eventTime}
                label="Event Time"
                onChange={handleEventTimeChange}
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#F8BBD0",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#F8BBD0",
                  },
                }}
              >
                {timeOptions.map((time) => (
                  <MenuItem key={time} value={time}>
                    {time}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        {deliveryType === "Delivery" && (
          <PinkBorderTextField
            id="standard-required"
            required
            variant="outlined"
            label="EVENT ADDRESS"
            value={eventAddress}
            onChange={(e) => setEventAddress(e.target.value)}
          />
        )}

        <Box>
          <Typography variant="body2">
            <span style={{ color: "red" }}>*</span>Required Field
          </Typography>
        </Box>

        <Box>
          <Typography variant="subtitle1" mb="0.5rem" fontWeight="400">
            Tell us a little bit about your event!
          </Typography>
          <PinkBorderTextField
            id="oulined-basic"
            variant="outlined"
            fullWidth
            multiline
            rows={10}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Typography variant="body2">
            For privacy and security purposes, please do not share your credit
            card or payment information in this field.
            {isDevice && <br />} Thank you.
          </Typography>
        </Box>

        <Box width="30%" margin="0 auto 2rem auto">
          <Button
            variant="contained"
            sx={{
              width: "100%",
              height: "3rem",
              borderRadius: "30px",
              backgroundColor: "#e9b6ce",
              padding: "1rem",
              "&:hover": {
                backgroundColor: "#e9b6ce",
              },
            }}
            onClick={handleSubmit}
            disabled={handleDisableSubmit()}
          >
            <Typography variant="h6">SUBMIT</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Catering;
