import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Divider,
  Button,
  Drawer,
} from "@mui/material";
import { IoRestaurantOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

interface SideDrawerProps {
  setToggleDrawer: Function;
  toggleDrawer: any;
}

const SideDrawer = ({ setToggleDrawer, toggleDrawer }: SideDrawerProps) => {
  const navigate = useNavigate();

  const getUrl = (text: string) => {
    if (text === "MENU") {
      return "/menu";
    } else if (text === "RESERVATIONS") {
      return "/reservations";
    } else if (text === "CATERING") {
      return "/catering";
    } else if (text === "CONTACT") {
      return "/contact";
    }
  };

  const handleLink = (text: string) => {
    navigate(getUrl(text) || "");
  };

  return (
    <Drawer
      anchor="bottom"
      open={toggleDrawer}
      onClose={() => setToggleDrawer(false)}
      sx={{
        padding: "0",
      }}
    >
      <Box width="auto" role="presentation" bgcolor={"#e9b6ce"} paddingBottom={"1rem"}>
        <List>
          {["MENU", "CATERING", "CONTACT"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  handleLink(text);
                  setToggleDrawer(false);
                }}
              >
                <ListItemText>
                  <Typography variant="subtitle2" textAlign={"center"}>
                    {text}
                  </Typography>
                  <Divider
                    sx={{
                      width: "10%",
                      margin: " 0.5rem auto",
                      backgroundColor: "black",
                      height: "1px",
                    }}
                  />
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Box
          width="10rem"
          display={"flex"}
          flexDirection={"column"}
          gap={"1rem"}
          justifyContent={"center"}
          alignItems={"center"}
          m={"0 auto"}
        >
          <Box width="100%">
            <Button
              variant="text"
              fullWidth
              sx={{
                // borderColor: "black",
                // borderBlockWidth: "2.5px",
                color: "black",
                // "&:hover": {
                //   backgroundColor: "black",
                //   color: "white",
                //   borderColor: "white",
                // },
              }}
              href="https://order-dreamland-cafe.square.site/"
            >
              <Typography variant="subtitle2" textAlign={"center"}>
                ORDER NOW
              </Typography>
            </Button>
            <Divider
              sx={{
                width: "25%",
                margin: " 0.5rem auto",
                backgroundColor: "black",
                height: "1px",
              }}
            />
          </Box>
          <Box>
            <Button
              variant="text"
              fullWidth
              sx={{
                // borderColor: "black",
                // borderBlockWidth: "2.5px",
                color: "black",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                  borderColor: "white",
                },
              }}
              onClick={() => {
                setToggleDrawer(false);
              }}
              href="https://www.opentable.ca/r/dreamland-cafe-reservations-ottowa?restref=1063837&lang=en-CA&ot_source=Restaurant%20website"
            >
              <Typography variant="subtitle2" textAlign={"center"}>
                RESERVATIONS
              </Typography>
            </Button>
            <Divider
              sx={{
                width: "25%",
                margin: " 0.5rem auto",
                backgroundColor: "black",
                height: "1px",
              }}
            />
          </Box>
        </Box>

        <Box margin="0 0 0 auto" width={50}>
          <IoRestaurantOutline size={30} color={"black"} />
        </Box>
      </Box>
    </Drawer>
  );
};

export default SideDrawer;
