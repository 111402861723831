import "./App.css";
import { Box, ThemeProvider } from "@mui/material";
import theme from "./hooks/use-theme/usetheme";
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import About from "./components/About";
import Catering from "./components/Catering";
import Menu from "./components/Menu";
import Contact from "./components/Contact";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Box id="top-anchor" overflow-x={"hidden"}>
      <ThemeProvider theme={theme}>
        <Router>
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/catering" element={<Catering />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </Box>
  );
}

export default App;
